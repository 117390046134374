import {useState} from 'react';

const App = () =>{

    const [toggleEmail, setToggleEmail] = useState(true)
    const saveEmail = function(){
      setToggleEmail(false)
      var email = document.getElementById('email').value
      var name = document.getElementById('name').value
      var message = document.getElementById('message').value
    
      var jsonData = {
        "email": email,
        "name": name,
        "message":message,

      }
        fetch("https://hackmint-loading-default-rtdb.firebaseio.com/TikolaEmailList.json", { 
        method: "POST",
        mode: "cors",
        body: JSON.stringify(jsonData)
        }
    );
}




  return (
  <body id="section_1">
    

    

    <nav className="navbar navbar-expand-lg bg-white shadow-lg" >
        <div className="container">

            <a href="#" className="navbar-brand">Tik<span className="text-danger">Ola</span></a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#section_1"> Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#section_2">About Tikola</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#section_3">How it works?</a>
                    </li>

                    

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#section_5">Contact Us</a>
                    </li>
                </ul>
            </div>
                    
        </div>
    </nav>

    <main>

        <section className="hero">
            <div className="container-fluid h-100" >
                <div className="row h-100">

                    <div id="carouselExampleCaptions" className="carousel carousel-fade hero-carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="container position-relative h-100">
                                    <div className="carousel-caption d-flex flex-column justify-content-center">
                                        <small className="small-title">Introducing Tikola Opinion App </small>

                                        <h1>Explore <span className="text-warning">new ways</span> to monetize your opinions</h1>

                                        <div className="d-flex align-items-center mt-4">
                                            <a className="custom-btn btn custom-link" href="#section_2">Get Started</a>

                                            <a className="popup-youtube custom-icon d-flex ms-4" href="">
                                                <i className="bi-play play-icon d-flex m-auto text-white"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-image-wrap">
                                    <img src="images/slide/background1.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="container position-relative h-100">
                                    <div className="carousel-caption d-flex flex-column justify-content-center">
                                        <small className="small-title">Make your opinions count </small>

                                        <h1>Predict future and <span className="text-warning">Earn real money</span></h1>

                                        <div className="d-flex align-items-center mt-4">
                                            <a className="custom-btn btn custom-link" href="#section_2">Explore Capabilities</a>

                                            <a className="popup-youtube custom-icon d-flex ms-4" href="">
                                                <i className="bi-play play-icon d-flex m-auto text-white"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="carousel-image-wrap">
                                    <img src="images/slide/background1.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>

                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>

                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </section>


        <section className="about section-padding" id="section_2">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                        <div className="about-image-wrap h-100">
                            <img src="images/projects/Intro_page_image.png" className="img-fluid about-image" alt="" />

                            
                        </div>
                    </div>

                    <div className="col-lg-6 col-12 d-flex flex-column">
                        <div className="about-thumb bg-white shadow-lg">
                            
                            <div className="about-info">
                                <small className="small-title">About TikolaApp</small>

                                <h2 className="mb-3">Predicting Future</h2>

                                <h5 className="mb-3">Helping indians to look at the news in a dramatically new way and monetize the information they have.</h5>

                                <p> What interests you? Is it Politics or Is it Entertainment or maybe Sports or something else.  </p>

                                <p>Choose the sector you feel you have a slight edge in information over others. Trade opinions on real outcomes to get to earn money.</p>
                            </div>
                        </div>

                        <div className="row h-100">
                            <div className="col-lg-6 col-6">
                                <div className="about-thumb d-flex flex-column justify-content-center bg-light mb-lg-0 h-100">
                                    
                                    <div className="about-info">
                                        <h5 className="mb-3">DON'T JUST CONSUME NEWS</h5>

                                        <p>You watch news, read news and have lots of opinions on the affairs going around</p>
                                        <p> </p>
                                        <p> </p>
                                        <p>Isn't it true?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-6">
                                <div className="about-thumb d-flex flex-column justify-content-center bg-warning mb-lg-0 h-100">
                                    
                                    <div className="about-info">
                                        <h5 className="text-white mb-4">USE NEWS</h5>

                                        <p className="text-white mb-0">Its time to use the news you read and watch and opinions you and people you know have. Why not earn money a small assistance of ours?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="services section-padding" id="section_3">
            <div className="container">
                <div className="row">

                    <div className="col-lg-10 col-12 text-center mx-auto mb-5">
                        

                        <h2>How TikOla works?</h2>

                    </div>

                    <div className="col-lg-6 col-12">
                        <nav>
                            <div className="nav nav-tabs flex-column align-items-baseline" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-business-tab" data-bs-toggle="tab" data-bs-target="#nav-business" type="button" role="tab" aria-controls="nav-business" aria-selected="true">
                                    <h3>READ NEWS</h3>

                                    <span>We provide you with a news item with a breif description. After the news there is a prompt on an opinion based exactly on that news. </span>
                                </button>

                                <button className="nav-link" id="nav-strategy-tab" data-bs-toggle="tab" data-bs-target="#nav-strategy" type="button" role="tab" aria-controls="nav-strategy" aria-selected="false">
                                    <h3>PREDICT YES OR NO</h3>
                                    
                                    <span>Predict YES if you feel affirmative and NO if you discard the possibility of the event happening. You can also check the proportion of people in favour and against.</span>
                                </button>

                                <button className="nav-link" id="nav-video-tab" data-bs-toggle="tab" data-bs-target="#nav-video" type="button" role="tab" aria-controls="nav-video" aria-selected="false">
                                    <h3>CHECKOUT DASHBOARD </h3>
                                    
                                    <span>After placing the order you can check out the Dashboard for real time updates of your orders. Wallet can also be checked out for debit or credit amount.</span>
                                </button>
                            </div>
                        </nav>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-business" role="tabpanel" aria-labelledby="nav-intro-tab">
                                <img src="images/services/feed_screen_wtbg.png" className="img-fluid" alt="" />

                                
                            </div>

                            <div className="tab-pane fade show" id="nav-strategy" role="tabpanel" aria-labelledby="nav-strategy-tab">
                                <img src="images/services/man_pointing_modalsheet.png" className="img-fluid" alt="" />

                            
                            </div>

                            <div className="tab-pane fade show" id="nav-video" role="tabpanel" aria-labelledby="nav-video-tab">
                                <img src="images/services/wallet_dashboard_screen.png" className="img-fluid" alt="" />

                                
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </section>

        

        <section className="contact" id="section_5">

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f9c10b" fill-opacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>

                <div className="contact-container-wrap">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6 col-12">
                                {toggleEmail
                                ?
                                    <div className="custom-form contact-form">
                                    <small className="small-title">Contact </small>

                                    <h2 className="mb-5">Say Hi to us</h2>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">                                    
                                                <input type="text" name="name" id="name" className="form-control" placeholder="Your Name" required="" />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-12">         
                                                <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="xyz@gmail.com" required="" />
                                            </div>

                                            <div className="col-12">
                                                <textarea className="form-control" rows="7" id="message" name="message" placeholder="Message"></textarea>

                                                <button type="submit" className="form-control" onClick={()=>saveEmail()}>Submit</button>
                                            </div>

                                        </div>
                                    </div>
                                
                                :
                                    <div>
                                        <h2>Thanks!</h2>
                                        <p>We will get in touch.</p>
                                    </div>
                                
                                }
                            
                            </div>

                            <div className="col-lg-6 col-12">
                                <div className="contact-thumb">
                                    
                                    <div className="contact-info bg-white shadow-lg">
                                    <footer className="footer">
                                        <div className="container">
                                            <div className="row">
                                            <div className="footer-col">
                                                    
                                            <ul>
                                                <li><a href="#">About us</a></li>
                                                <li><a href="#">Blogs</a></li>
                                                
                                            </ul>
                                                </div>

                                            <div className="footer-col">
                                                    
                                            <ul>
                                                
                                                <li><a href="#">Team</a></li>
                                                <li><a href="#">Contact</a></li>
                                            </ul>
                                            </div>
                                            <div className="footer-col">
                                            
                                            <ul>
                                                <li><a href="#">T&C</a></li>
                                                <li><a href="#">Privacy Policy</a></li>
                                                
                                            </ul>
                                            </div>

                                        

                                            <div className="footer-col">
                                            
                                            <ul>
                                                
                                                <li><a href="#">Refund</a></li>
                                                <li><a href="#">Disclaimer</a></li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </footer>
                                                                    
                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </section>

    </main>

    <footer className="site-footer">
        <div className="container">
            <div className="row">
                    
                
                
                <div className="col-lg-6 col-12">
                  <p className="copyright-text mb-0 me-4">@2022-23 Renaissance Tech Pvt. Ltd. All rights reserved.</p>
                </div>

            </div>
        </div>
    </footer>



</body>
  );
}

export default App;
